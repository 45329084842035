<template>
    <div id="nav">
        <router-link class="router-link" :to=newUrl>
            <font-awesome-icon :icon="['fas', 'plus-circle']" />
            <span>{{ titel }}</span>
        </router-link> 
    </div>
    <PopupComponent :artno=artno :titel=titel :beskrivning=beskrivning :pris=pris :image=image :style="styleObject"></PopupComponent>
</template>
<script>
   import PopupComponent from '../components/PopupComponent.vue'

    export default {
        name: 'MachineComponent',
        data () {
            return {
            styleObject: {
                display: 'none'
            }}
        },
        components: {
            PopupComponent
        },
        props: {
            id : String,
            titel:  String,
            kategori : String,
            beskrivning: String,
            pris: String,
            artno: String,
            image: Number
        },
        computed: {
            imgUrl: function () {
                return "https://liof.se/static/img/" + this.artno.toLowerCase() + ".jpeg"
            },
            newUrl: function () {
                return "/maskin/" + this.titel
            }
        },
        watch: {
            $route(theRoute) {
                if(this.styleObject.display == 'block' && theRoute.params.id == '') this.styleObject.display = 'none'
                else if(this.titel == theRoute.params.id) this.styleObject.display = 'block'
            }
        } 
    }
</script>
