<template>
  <div class="content-page">
    <div class="top">
        <h1>Kontakt</h1>    
    </div>
    <div class="bot" v-if=workers>
      <WorkerComponent v-for="worker in workers" :key=worker.id :fornamn=worker.fornamn :efternamn=worker.efternamn :titel=worker.titel :telefonnummer=worker.telefonnummer :mail=worker.mail></WorkerComponent>
    </div>
    <div class="bot" v-else>
        <h2>Problem med hämtning av anställda...</h2>
    </div>
    </div>
</template>
<script>
  import WorkerComponent from '../components/WorkerComponent.vue'

  export default {
    name: 'Kontakt',
    components: {
      WorkerComponent
    },
    data () {
      return {
        workers: null
      }
    },
    created () {
      //this.workers = JSON.parse('[{"id":"1","fornamn":"Tomas","efternamn":"Hermansson","titel":"VD","telefonnummer":"070-818 18 00","mail":"tomas.levene@gmail.com","active":"1"},{"id":"2","fornamn":"Johanna","efternamn":"Andersson","titel":"Ekonomi","telefonnummer":"070-672 34 76","mail":"johanna.levene@gmail.com","active":"1"},{"id":"3","fornamn":"Dan","efternamn":"Hermansson","titel":"Maskiner","telefonnummer":"070-521 60 21","mail":"ullah1944@hotmail.com","active":"1"},{"id":"4","fornamn":"Ida","efternamn":"Hermansson","titel":"Utveckling","telefonnummer":"076-808 72 90","mail":"i.hermansson92@gmail.com","active":"1"},{"id":"5","fornamn":"Karl","efternamn":"Hermansson","titel":"IT","telefonnummer":"073-328 36 63","mail":"karl-hermansson@gmail.com","active":"1"}]');
      this.axios.get("https://liof.se/static/db/arbetare.php").then(response => (this.workers = response.data)).catch(() => {this.workers = null})   
    }
  }
</script>
