<template>
<div>
    <router-link class="router-link" to="/maskin" id="coverlayer" tag="div" >
    </router-link>

    <div id="popup">
        <div id="pop-content">
            <div class="half-card">
                <img :src=imgUrl :alt=titel>
            </div>
            <p style="font-weight: bold">{{ titel }}</p>
            <h4>{{ beskrivning }}</h4>
            <p v-if=pris> Pris: {{ pris }}kr.</p>
            <h4>Kontakta Dan Hermansson på <a class="contact" href="tel:070-521 60 21">070-521 60 21</a> eller via <a class="contact" href="mailto:dan@liof.se">dan@liof.se</a> för mer information.</h4>
        </div>    
    </div>
</div>
</template>
<script>
    export default {  
        name: 'PopupComponent',
        props: {
            titel: String,
            beskrivning : String,
            artno: String,
            pris : String,
            image : Number
        }, 
        computed: {
            imgUrl: function () {
                if(this.image == 1) return "https://liof.se/static/img/" + this.artno.toLowerCase() + ".jpeg" 
                else return "https://liof.se/static/img/fallback.jpeg"
            }
        }
    }
</script>
<style scoped>
    #coverlayer{
        display:inherit;
        position: fixed;
        top: 0%;
        right: 0%;
        left: 0%;
        bottom: 0%;
        background : rgba(55, 55, 60, 0.97);
    }
    #popup{
        background-color: #2c3e50;
        position: fixed;
        top: 20%;
        right: 20%;
        left: 20%;
        bottom: 20%;
        border-radius: .4em;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
