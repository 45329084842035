<template>
  <div class="content-page">
    <div class="top"> 
      <h1>Impregnering</h1>
      <img src="@/assets/images/imp.jpeg" alt="Italian Trulli">
    </div>
    <div class="bot">
      <p> 
        Det finns situationer då det är svårt att utforma konstruktionen på sådant sätt att träet inte utsätts för höga fukthalter. I sådana lägen kan det vara fördelaktigt att använda sig av tryckimpregnerade trävaror.
      </p>
      <p>
        I vår automatiserade anläggning tryckimpregnerar vi av både gran och furu. 
      </p>
      <p>
        Är du intresserad av att vidareförädla ditt virke med hjälp av tryckimpregnering?  Tveka då inte på att höra av dig till oss!  
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Impregnering',
  }
</script>