<template>
  <div class="content-page">
    <div class="top"> 
      <h1>404 Not Found</h1>
    </div>
    <div class="bot">
      <h3>
      <router-link  class="router-link" to="/">
        <font-awesome-icon :icon="['fas', 'home']" /> Go Back To the Homepage
      </router-link> 
      </h3>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Not Found',
  }
</script>