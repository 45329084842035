<template>
    <div class="card">
        <div class="half-card">
            <img :src="require('@/assets/images/' + fornamn + '.jpeg')" :alt="fornamn">
        </div>
        <div class="half-card">
            <p style="font-weight: bold">{{ fornamn }} {{ efternamn }}</p>
            <p>{{ titel }}</p>
            <h4><a class="contact" :href="`tel:${telefonnummer}`">{{ telefonnummer }}</a></h4>
            <h4><a class="contact" :href="`mailto:${mail}`">{{ mail }}</a></h4>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'WorkerComponent',
        props: {
            fornamn:  String,
            efternamn: String,
            titel: String,
            telefonnummer: String,
            mail: String
        },
    }   
</script>
