<template>
  <div class="content-page">
    <div class="top"> 
      <h1>Välkommen</h1>
    </div>
    <div class="bot">
      <p> 
        Familjen Hermansson har varit i sågverksbranschen sedan år 1939 då Erik Hermansson grundade Levene Såg. Sågverket drevs vidare inom familjen Hermansson fram tills år 2021 då en ny möjlighet uppstod i kölvattnet av corona pandenmin.
      </p>
      <p>
        Den nya möjligheten blev Levene Impregnering & Försäljning, ett företag med fokus på hållbar trävaruförädling och försäljning av sågverksmaskiner.
      </p>
      <p>
        Levene Impregnering & Försäljning har, med tre generationer i verksamheten, en stark familjekärna med mångårig erfarenhet av trävarubranchen. Vi säljer allt ifrån hela anläggningar till reservdelar inom sågverksindustrin. Vi har ett stort lager med både nya och beganade artiklar. Vi har även en tryckimpregneringsanläggning där vi kan förädla ditt virke. Så behöver du hjälp med att impregnera ditt virke, förnya ditt sågverk eller behöver du reservdelar till din befintilga anläggning? Tveka inte på att höra av dig till oss eller kicka in under ”Maskin”-fliken för att se ett axplock av vad vi har till salu för tillfället.
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Hem',
  }
</script>